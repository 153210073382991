[data-layout-mode="dark"] {

    $gray-100: #212529;
    $gray-200: #121e2d;
    $gray-300: #1d2d42;
    $gray-400: #a6b0cf;
    $gray-500: #bfc8e2;
    $gray-600: #c3cbe4;
    $gray-700: #f6f6f6;
    $gray-800: #eff2f7;
    $gray-900: #f8f9fa;


    $grays: ("100": $gray-100,
            "200": $gray-200,
            "300": $gray-300,
            "400": $gray-400,
            "500": $gray-500,
            "600": $gray-600,
            "700": $gray-700,
            "800": $gray-800,
            "900": $gray-900);

    // Prefix for :root CSS variables
    @each $color,
    $value in $grays {
        --#{$variable-prefix}gray-#{$color}:            #{$value};
    }

    //body
    --#{$prefix}body-bg:                                #{darken($gray-200, 2.5%)};
    --#{$prefix}body-color:                             #{$gray-600};
    --#{$prefix}heading-color:                          #{$gray-600};
    --#{$prefix}border-color:                           #{$gray-300};
    --#{$prefix}light-rgb:                              #{to-rgb($gray-300)};
    --#{$prefix}dark-rgb:                               #{to-rgb($gray-300)};
    --#{$prefix}white:                                  #{$gray-200};
    --#{$prefix}light:                                  #{$gray-300};
    --#{$prefix}black:                                  #{$gray-700};

    //top-header
    --#{$prefix}header-dark-bg:                         #{$gray-200};
    --#{$prefix}header-bg:                              #{$gray-200};
    --#{$prefix}topnav-bg:                              #{$gray-200};
    --#{$prefix}header-dark-item-color:                 #{$gray-600};

    // apexchart
    --#{$prefix}apex-grid-color:                        #{$gray-200};

    // form
    --#{$prefix}input-border-color:                     #{$gray-300};
    --#{$prefix}input-focus-border-color:               #{lighten($gray-300, 1%)};
    --#{$prefix}input-color:                            #{$gray-600};
    --#{$prefix}input-bg:                               #{lighten($gray-200, 2.5%)};
    --#{$prefix}form-select-focus-border-color:         #{lighten($gray-300, 1%)};

    // footer
    .footer{
        --#{$prefix}footer-bg:                          #{$gray-200};
        --#{$prefix}footer-color:                       $black;    
    }

    .dropdown-menu{
        --#{$prefix}dropdown-bg:                        #{lighten($gray-200, 2%)};
        --#{$prefix}dropdown-color:                     #{$gray-500};
        --#{$prefix}dropdown-border-color:              #{$gray-300};
        --#{$prefix}dropdown-link-color:                #{$gray-500};
        --#{$prefix}dropdown-link-hover-color:          #{$gray-500};
        --#{$prefix}dropdown-link-hover-bg:             #{lighten($gray-200, 3.5%)};
    }

    .dropdown{
        --#{$prefix}btn-border-color:                    $body-color;
    }

    .card,
    .page-title-box {
        --#{$prefix}card-bg:                            #{$gray-200};
        --#{$prefix}card-border-color:                  #{lighten($gray-200, 2.5%)};
    }

    .accordion {
        --#{$prefix}accordion-color:                    #{$gray-100};
    }

    .list-group {
        --#{$prefix}list-group-color:                   #{$gray-500};
        --#{$prefix}list-group-bg:                      #{lighten($gray-200, 2.5%)};
        --#{$prefix}list-group-border-color:            #{$gray-300};
    }

    .accordion {
        --#{$prefix}accordion-bg:                       #{$gray-200};
        --#{$prefix}accordion-btn-bg:                   #{$gray-200};
        --#{$prefix}accordion-active-bg:                #{rgba($primary, 0.2)};
        --#{$prefix}accordion-color:                    #{$gray-600};
    }

    .pagination {
        --#{$prefix}pagination-bg:                      #{$gray-200};
        --#{$prefix}pagination-hover-bg:                #{$gray-200};
        --#{$prefix}pagination-disabled-bg:             #{$gray-200};
        --#{$prefix}pagination-border-color:            #{lighten($gray-200, 2.5%)};
        --#{$prefix}pagination-hover-border-color:      #{lighten($gray-200, 2.5%)};
        --#{$prefix}pagination-disabled-border-color:   #{lighten($gray-200, 2.5%)};
    }

    .progress {
        --#{$prefix}progress-bg: #{$gray-300};
    }

    .table {
        --#{$prefix}table-striped-bg:                    #{$gray-300};
        --#{$prefix}table-color:                         #{$gray-600};
        --#{$prefix}table-striped-color:                 #{$gray-600};
        --#{$prefix}table-border-color:                  #{$gray-300};
        --#{$prefix}table-active-bg:                     #{lighten($gray-200, 1.5%)};
        --#{$prefix}table-active-color:                  #{$gray-400};
        --#{$prefix}table-hover-bg:                      #{lighten($gray-300, 1.5%)};
        --#{$prefix}table-hover-color:                   #{$gray-400};
    }

    .table-bordered{
        --#{$prefix}border-color:                  #{$gray-300};
    }
    
    .table-dark {
        --#{$prefix}table-bg:                            #{$gray-300};
        --#{$prefix}table-border-color:                  #{darken($gray-300, 2%)};
        --#{$prefix}border-color:                        #{darken($gray-300, 2%)};
        --#{$prefix}table-dark-border-color:             #{darken($gray-300, 2%)};
    }

    .table-light {
        --#{$prefix}table-bg:                            #{lighten($gray-300, 1.5%)};
        --#{$prefix}table-border-color:                  #{lighten($gray-200, 5%)};
        --#{$prefix}table-color:                         #{$gray-400};
        --#{$prefix}border-color:                        #{lighten($gray-200, 5%)};
                     
    }

    .blockquote {
        --#{$prefix}card-border-color:                   #{$gray-300};
    }

    .btn{
        --#{$prefix}btn-link-color:                      #{$gray-600};
    }

    .btn-light {
        --#{$prefix}btn-color:                           #{$gray-600};
        --#{$prefix}btn-bg:                              #{$gray-300};
        --#{$prefix}btn-border-color:                    #{$gray-300};
    }
    
    .btn-outline-light {
        --#{$prefix}btn-color:                           #{$gray-600};
        --#{$prefix}btn-border-color:                    #{$gray-300};
    }
    
    .btn-light, .btn-outline-light {
        --#{$prefix}btn-hover-color:                     #{$gray-600};
        --#{$prefix}btn-hover-bg:                        #{$gray-300};
        --#{$prefix}btn-hover-border-color:              #{$gray-300};
        --#{$prefix}btn-active-color:                    #{$gray-600};
        --#{$prefix}btn-active-bg:                       #{$gray-300};
        --#{$prefix}btn-active-border-color:             #{$gray-300};
        --#{$prefix}btn-focus-shadow-rgb:                #{to-rgb($gray-300)};
    }

    .btn-dark {
        --#{$prefix}btn-bg:                              #{lighten($gray-200, 2%)};
        --#{$prefix}btn-border-color:                    #{lighten($gray-200, 2%)};
        --#{$prefix}btn-hover-bg:                        #{lighten($gray-200, 2%)};
    }
    
    .btn-outline-dark {
        --#{$prefix}btn-color:                           #{lighten($gray-200, 2%)};
        --#{$prefix}btn-border-color:                    #{lighten($gray-200, 2%)};
    }
    
    .btn-dark, .btn-outline-dark  {
        --#{$prefix}btn-hover-border-color:              #{lighten($gray-200, 2%)};
        --#{$prefix}btn-active-bg:                       #{lighten($gray-200, 2%)};
        --#{$prefix}btn-active-border-color:             #{lighten($gray-200, 2%)};
        --#{$prefix}btn-focus-shadow-rgb:                #{to-rgb($gray-300)};
    }
    

    .toast{
        --#{$prefix}toast-bg:                            #{lighten($gray-300, 2%)};
        --#{$prefix}toast-header-bg:                     #{lighten($gray-300, 2.5%)};
    }

    .btn-close{
        --#{$prefix}btn-close-color:                     #{$gray-600};
    }

    .modal-content{
        --#{$prefix}modal-border-color:                  #{lighten($gray-200, 2.5%)};
        --#{$prefix}modal-bg:                            #{$gray-200};
        --#{$prefix}modal-header-border-color:           #{$gray-300};
        --#{$prefix}modal-footer-border-color:           #{$gray-300};
    }

    .offcanvas{
        --#{$prefix}offcanvas-bg:                        #{$gray-200};
        --#{$prefix}offcanvas-border-color:              #{$gray-300};
    }
    
}